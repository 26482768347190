tr.row > * {
	border: solid 1px #e0e0e0;
	padding: 4px 8px;
}

tr.head > * {
	border: solid 1px #e0e0e0;
	background-color: #fafafa;
	padding: 8px;
}
