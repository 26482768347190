#profile-avatar {
	width: 180px;
	height: 180px;
}

#profile-avatar-border {
	width: 180px;
	height: 180px;
	padding: 3px;
	margin: auto;
	margin-bottom: 24px;
	border-radius: 50%;
	border: solid 1px #d5d5d5;
}

#profile-form {
	padding: 8;
	position: relative;
	max-height: calc(100vh - 306px);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

#avatar-change-button {
	bottom: 54px;
	float: right;
	width: 40px;
	height: 40px;
}

.profile-attr {
	font-weight: bold;
	/* flex: 1; */
}

.profile-attr-value {
	flex: 1;
	text-align: right;
}

#card-content-asdck {
	overflow: auto;
	max-height: calc(100% - 70px);
}

/* #card-actions-ercoep {
  position: fixed;
  bottom: 0;
}

#card-content-asdck {
  max-height: calc(100% - 70px);
} */
