#sidebar {
	z-index: 3;
	height: 100vh;
	transition: 0.5s;
	position: fixed;
	background-color: #233473; /* 001e3c */
}

#sidebar-mobile {
	height: 100vh;
	width: 100%;
	background-color: #233473; /* 001e3c */
}

#logo {
	width: 100%;
	height: 64px;
	color: white;
	font-size: 32px;
	line-height: 64px;
	text-align: center;
}

#logo-text {
	height: 64px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 8px;
}
x #logo-text b {
	color: #fff;
}

#menu {
	padding: 0;
	width: 100%;
	position: absolute;
}

.menu-item {
	/* padding: 8px 0; */
}

.menu-item:hover {
	background-color: #505050;
}

#open-btn {
	bottom: 8px;
	width: calc(100% - 16px);
	width: 100%;
	/* height: 48px; */
	/* padding: 0 8px; */
	color: #fff;
	position: absolute;
}

/* .sidebar-badge > .MuiBadge-anchorOriginTopRightRectangle {
  top: 4px;
  right: 24px;
}

.work-tab-badge > .MuiBadge-anchorOriginTopRightRectangle {
} */

#accordionSummary {
	min-height: 43px;
}

#accordionSummary > .MuiAccordionSummary-content.Mui-expanded {
	margin: 0 !important;
}

#accordionSummary > .MuiAccordionSummary-content {
	margin: 0 !important;
}

#accordionSummary > .MuiAccordionSummary-expandIcon {
	padding: 0 !important;
}

#accordionSummary:hover {
	background-color: #f59b73 !important;
}
