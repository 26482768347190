html {
	background-color: #f4f4f4;
}

@font-face {
	font-family: 'SFProDisplay';
	src:
		local('SFProDisplay'),
		url(./assets/fonts/SFProDisplay-Regular.ttf) format('truetype');
}

body {
	margin: 0;
	font-family: 'SFProDisplay';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	cursor: pointer;
	text-decoration: underline;
}

a.MuiLink-underlineHover:hover {
	text-decoration: none;
}

div.modal-text-field {
	width: '100%';
}

.table-action-buton {
	cursor: pointer;
	display: block;
}

.MuiListItem-root {
	margin: 0 !important;
	border-radius: 0 !important;
}

@media (min-width: 600px) {
	.MuiTab-root {
		min-width: auto !important;
	}
}

.MuiFormLabel-root {
	background-color: #fff;
	padding: 0 2px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	display: none;
}

.error-button {
	background-color: #d32f2f !important;
}

.error-button:hover {
	background-color: #b52f2f !important;
}

.update-button {
	background-color: #f26526 !important;
}

.success-button {
	background-color: #247438 !important;
}

.success-button:hover {
	background-color: #1b592b !important;
}

.kpt-textfield-label {
	/* color: #031043 !important; */
	font-size: 17px !important;
	font-weight: bold !important;
	/* padding-bottom: 4px; */
}

.MuiDropzoneArea-text {
	margin: 8px 0 !important;
	font-size: 16px !important;
	font-weight: bold !important;
}

.MuiDropzoneArea-root {
	min-height: 120px !important;
}

.MuiDropzoneArea-icon {
	width: 32px !important;
	height: 32px !important;
}

.MuiDivider-root {
	background-color: #e0e0e0 !important;
}

/* .MuiStepConnector-lineHorizontal {
  border-top-width: 4px !important;
  border-color: #ee6064 !important;
} */

.step-modal .MuiStepConnector-lineHorizontal {
	border-top-width: 4px !important;
	border-color: #243474 !important;
}

/* .MuiChip-root { width: 100% } */

.MuiInputBase-input.Mui-disabled {
	background-color: #fafafa !important;
}

.MuiInputBase-root.Mui-disabled {
	background-color: #fafafa !important;
}

.group-btn {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
	border-radius: 4px !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
	border-radius: 4px !important;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.ck-editor__editable {
	min-height: calc(100vh - 439px - 280px);
	/* min-height: 240px; */
	/* max-height: 240px; */
}

/* .MuiTableCell-sizeSmall {
  padding: 6px 16px !important;
} */
