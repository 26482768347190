.numIcon {
	font-size: 14px !important;
	transition: 0.1s;
	color: #bdbdbd;
	cursor: pointer;
}

.numDisabledIcon {
	font-size: 14px !important;
	transition: 0.1s;
	color: #bdbdbd;
}

.numIcon:hover {
	color: #000;
}

.disabled {
	cursor: auto;
	color: #00000061;
	background-color: #fafafa;
}

.dateContainer:hover {
	border-color: #000000da;
}

.disabled:hover {
	border-color: #c4c4c4 !important;
}
