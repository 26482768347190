#em-contract-header-container {
	padding: 4px;
	display: flex;
	width: calc(100% - 4px);
}

.em-contract-header-item {
	text-align: left;
}

div.MuiAccordionSummary-root.Mui-expanded {
	min-height: 0px;
}

div.em-contract-cell-header {
	padding: 0px;
	min-height: auto;
	line-height: 38px;
}

div.em-contract-cell-container {
	box-shadow: none;
}
