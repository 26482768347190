.fileField {
	border-bottom: none !important;
}

.fileField::after {
	border-bottom: none !important;
}

.fileField::before {
	border-bottom: none !important;
}

.label {
	top: -4px;
	left: 12px;
	height: 11px;
	padding: 0 4px;
	line-height: 1px;
	font-size: 0.75em;
	color: #0000008a;
	position: absolute;
	background-color: #fff;
}

.focus {
	font-size: 14px !important;
	transition: 0.1s;
	color: #243474;
	cursor: pointer;
}

.numIcon {
	font-size: 14px !important;
	transition: 0.1s;
	color: #bdbdbd;
	cursor: pointer;
}

.numDisabledIcon {
	font-size: 14px !important;
	transition: 0.1s;
	color: #bdbdbd;
}

.numIcon:hover {
	color: #000;
}

.btn {
	height: 40;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
